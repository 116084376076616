import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// ship of fools
import {Container, Tab, Tabs, Card, Row, Col, Image} from "react-bootstrap";

export const Home = () => {

  const [selectedKey, setSelectedKey] = useState(window.location.hash
    ? window.location.hash.substring(1)
    : "home");


  const handleSelect = (key) => {
    setSelectedKey(key);
    window.location.hash = "#" + key;
  }

  const location = useLocation();

  useEffect(() => {
    setSelectedKey(location.hash ? location.hash.substring(1) : "home");
  },[location]);

  return (
    <Container float>

    <Tabs
        // defaultActiveKey="home"
        activeKey={selectedKey}
        className="mt-5 tab-block-c"
        onSelect={handleSelect} 
      >
        <Tab.Pane eventKey="home" title="Home" className="tab-c">
          <Card className="card-c">
            <Row>
              <Col md={4} className="col-c">
                <Image className="myimg" src="../menasa.jpg" rounded></Image>
                {/* <p className="capt-c">Me in Carmel, CA</p> */}
              </Col>
              <Col md={8} className="col-c">
               <p><h1 className="inline">About Justus</h1></p>

               <p>I’m an incoming software engineer intern at Microsoft, for the <a href="https://azure.microsoft.com/en-us/products/category/compute">Azure Compute</a> team.</p>

                <p>I’m an honors computer science student at Texas A&M University.</p>

                <p>Last summer I interned at NASA in the 
                  <a href="https://www.nasa.gov/software-robotics-and-simulation-division/robotic-systems-technology-branch/"> Robotics Systems Technology Branch</a>, at JSC in Houston.

                More details about my employment history are avaiable <a href="https://drive.google.com/file/d/1r-1suzH5FkKf69UNThph7bWcknlHP9cU/view?usp=sharing">on my resume</a>.</p>

                {/* <h5>Education</h5> */}

                <p>I spend <s>most</s> <i>all</i> of my free time working on programming projects.
                
                But I also enjoy american football and learning how to cook.
                </p>


              </Col>
            </Row>
          </Card>
        </Tab.Pane>

        <Tab eventKey="contact" title="Contact" className="tab-c">
          <Card className="card-c">
          <Row>
              <Col md={4} className="col-c">
                <Image className="myimg" src="../jsc1.webp" rounded></Image>
                {/* <p className="capt-c">Johnson Space Center</p> */}
              </Col>
              <Col md={8} className="col-c">
               <p><h1 className="inline">Contact</h1></p>
               <p>Feel free to email me at <a href="mailto:jus@justusl.com">jus@justusl.com</a>,</p>
               <p>or at my university email <a href="mailto:justus@tamu.edu">justus@tamu.edu</a>.</p>

               <p>My LinkedIn profile is <a href="https://www.linkedin.com/in/justusl">linkedin.com/in/justusl</a>.</p>
               <p>My GitHub profile is <a href="https://www.github.com/Juicestus">github.com/juicestus</a>.</p>
              </Col>
            </Row>
          </Card>
        </Tab>
      
      </Tabs>

   


    </Container>
  );
}

// export default Home;
