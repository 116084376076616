import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// ship of fools
import {Container, Tab, Tabs, Card, Row, Col, Image} from "react-bootstrap";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore"; 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDn4Y0DftX4nArvnS6xClRYJpNI8gaQ8PQ",
  authDomain: "justusl-telem.firebaseapp.com",
  projectId: "justusl-telem",
  storageBucket: "justusl-telem.firebasestorage.app",
  messagingSenderId: "670906057856",
  appId: "1:670906057856:web:4223b5f04f7d304ebd5265",
  measurementId: "G-YEQHV34NG7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);

const importAll = (r) => r.keys().map(r);

export const Home = () => {

  const [ip, setip] = useState();
  const [ipInfo, setipInfo] = useState();

  document.addEventListener("DOMContentLoaded", function() {
    // Fetch the IP address from the API
    fetch("https://api.ipify.org?format=json")
        .then(response => response.json())
        .then(data => {
            const _ip = data.ip;
            setip(_ip);

               // Fetch the IP address from the API
               // https://api.ipstack.com/198.41.227.191?access_key=1ec3060f653e1426513016bda36f4c49&format=1
            fetch("https://api.ipstack.com/" + _ip + "?access_key=1ec3060f653e1426513016bda36f4c49&format=1")
              .then(response => response.json())
              .then(data => {
                  console.log(data);
                  setipInfo(data);
                  // if (window.location.href.includes("localhost")) return;
                  setDoc(doc(db, "connections", "" + Date.now()), {
                    ip: _ip,
                    info: data,
                    referrer: document.referrer ?? "none"
                  });
              })
              .catch(error => console.error("Error fetching IP information:", error) );
        })
        .catch(error => console.error("Error fetching IP address:", error) );
  });

  const HREF = (link) => {
    return () => {
      setDoc(doc(db, "redirects", "" + Date.now()), {
        ip: ip,
        info: ipInfo,
        redirect: link,
        referrer: document.referrer ?? "none"
      });

      window.location.href = link;
    }
  }

  const [selectedKey, setSelectedKey] = useState(window.location.hash
    ? window.location.hash.substring(1)
    : "home");


  const handleSelect = (key) => {
    setSelectedKey(key);
    window.location.hash = "#" + key;
  }

  const location = useLocation();

  useEffect(() => {
    setSelectedKey(location.hash ? location.hash.substring(1) : "home");
  },[location]);

  // const imgfilenames = importAll(require.context('../../public/yum', false, /\.(png|jpe?g|svg|JPE?G)$/));

  return (
    <Container float className="main-container">

      <br></br>
      <br></br>

      {/* <h1>My name is Justus and this is my website.</h1> */}
      {/* <h1>[justusl.com]&nbsp; Justus&nbsp;Languell</h1> */}
      <h1 className="ctrtxt">Justus&nbsp;Languell&nbsp;&nbsp;</h1>
      <br></br>

      <img className="floaty-img" src="../menasa.jpg" rounded></img>

      <h2>About Me</h2>

      <p>I’m an incoming software engineer intern at <b>Microsoft</b>, for the <a href="#" onClick={HREF("https://azure.microsoft.com/en-us/products/category/compute")}>Azure Compute</a> team.</p>

      <p>I’m an honors computer science student at <b>Texas A&M University</b>.</p>

      <p>Last summer I interned at <b>NASA</b> with the <a href="#" onClick={HREF("https://www.nasa.gov/software-robotics-and-simulation-division/robotic-systems-technology-branch/")}>Robotics Systems Technology Branch</a> in Houston.

      More details about my employment history are avaiable <a href="#" onClick={HREF("https://drive.google.com/file/d/1r-1suzH5FkKf69UNThph7bWcknlHP9cU/view?usp=sharing")}>on my resume</a>.</p>

      <p>I spend <s>most</s> <i>all</i> of my free time working on programming projects.
      
      But I also enjoy american football and learning how to cook.
      </p>

      <br></br>


    <hr></hr>
    <h2>Contact/Socials</h2>

    <p>
      <ul>

      <li>Feel free to email me at <b>jus@justusl.com</b></li>
      <li>Or my university email <b>justus@tamu.edu</b>.</li>
      <li>My LinkedIn profile is <a href="#" onClick={HREF("https://linkedin.com/in/justusl")}>linkedin.com/in/justusl</a>.</li>
      <li>My GitHub profile is <a href="#" onClick={HREF("https://github.com/juicestus")}>github.com/juicestus</a>.</li>
      </ul>
    </p>

    <hr></hr>
    <h2>What am I doing?</h2>

      <p>I have been developing <a href="#" onClick={HREF("https://github.com/Juicestus/amd-media-assistant")}>AMD Media Assistant</a>, an Android 
      media assistant for people with Severe Age-Related Macular Degeneration (AMD), with <a href="#" onClick={HREF("https://engineering.tamu.edu/mechanical/profiles/thyagarajan-ravi.html")}>Dr. Ravi Thyagarajan</a>.</p>

      <p>Helping build an electric racecar with the Texas A&M Formula team

        <ul>
          <li><a href="#" onClick={HREF("https://github.com/TAMU-Formula-SAE-EV/TelemetryCore")}>TelemetryCore</a>, a fast telemetry proxy for on-vehicle CAN network</li>
          <li><a href="#" onClick={HREF("/BMS.pdf")}>distributed battery management system</a> (DBMS)</li>
        </ul>
      </p>


{/* 
    <hr></hr>
    <h2>List of other past projects</h2>

    <p>
      <a href=""></a>



    </p> */}


    {/* <hr></hr>
    <h2>Tasty Image Gallery</h2>

    <div className="img-container">
    {imgfilenames.map((img, index) => (<>
      <img className="grid-img" src={img.default} rounded></img>
      </>))}
    </div>
    <br></br> */}
   

    {/* <hr></hr> */}
    {/* <h2>Bob's Club</h2> */}


    {/* <b>This site is a member of <a href="https://bobs-club.net/">bob's club!</a> check out a friend's website too</b><br></br>
    <a href="https://bobs-club.net/site/justusl/pred">previous site</a> --
    <a href="https://bobs-club.net/site/justusl/random">random site</a> --
    <a href="https://bobs-club.net/site/justusl/succ">next site</a> */}

   {/* <p><span>
      I am part of Bob's Club. Check out a friend's website too.
      &nbsp;
      <a href="https://bobs-club.net/site/justusl/pred" title="previous site">&lt;&lt;</a>
      &nbsp;
      <a href="https://bobs-club.net/site/justusl/random" title="random site">?</a>
      &nbsp;
      <a href="https://bobs-club.net/" title="web ring">bob's club</a>
      &nbsp;
      <a href="https://bobs-club.net/site/justusl/succ" title="next site">&gt;&gt;</a>
    </span></p> */}

    {/* <hr></hr> */}
{/*  
      <div className="xfooter">

      Copyright © 2025 Justus Languell 
      </div> */}




    </Container>
  );
}

// export default Home;
