import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { Home } from "./components/Home";

const goto = (path, to) => (<Route path={path} component={() => {
    window.location.replace(to);
    return ;
}}/>);


ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/index" element={<Home />} />
      <Route path="/index" element={<Home />} />
      //{goto("/howdyhack", "https://master--howdyhack.netlify.app/")}
            {/*<Goto path="/devpost" to={"https://devpost.com/software/howdyhack-ai-powered-python-tutor"}/>*/}
      {/*<Route path="*" element={<Home />} />*/}
    </Routes>
  </Router>,

  document.getElementById("root")
);

serviceWorker.unregister();
